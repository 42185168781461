.downloader {
  width: 580px;
  min-height: 128px;
  position: fixed;
  right: 18px;
  bottom: 100px;
  max-height: 700px;
  overflow-y: auto;
}
.downloader .card-header {
  color: #fff;
  background-color: rgb(93 11 11 / 92%);
}
.downloader .card .list-group {
  max-height: 300px;
  overflow: hidden;
  overflow-y: auto;
}
@media only screen and (max-width: 580px) {
  .downloader {
    width: 90%;
    right:5%;
    left:5%;

  }
}